<template>
  <div class="reply-supply-evaluation-list">
    <el-descriptions class="info-cotainer" :column="4">
      <el-descriptions-item label="订单号">
        <el-link type="primary" @click="handleGo">{{ detailsInfo.serviceNo }}</el-link>
      </el-descriptions-item>
      <el-descriptions-item label="服务供应商">{{ detailsInfo.storeName }}</el-descriptions-item>
      <el-descriptions-item label="评价时间">{{ detailsInfo.createTime }}</el-descriptions-item>
    </el-descriptions>
    <div class="hr"></div>
    <div class="detail-title">
      <span class="title-mark"></span>
      <span class="title-text">投诉建议</span>
    </div>
    <div class="score" v-for="(item, key, i) in 1" :key="i">
      <div class="score-content">
        <div class="text" v-if="!!detailsInfo.suggest">{{ detailsInfo.suggest }}</div>
        <div class="text" style="color:#999" v-if="!detailsInfo.suggest">暂无内容</div>
        <span class="score-img" v-for="(itemj, index) in detailsInfo.suggestPictureUrl" :key="index">
          <img class="certificate-img" :src="itemj" alt="" :preview-src-list="detailsInfo.suggestPictureUrl" />
        </span>
      </div>
    </div>
    <div class="detail-title">
      <span class="title-mark"></span>
      <span class="title-text">投诉建议回复</span>
    </div>
    <div class="score-text-area">
      <el-form class="form-container" ref="form" :model="form" :rules="rules" label-width="0">
        <el-form-item prop="suggestReply">
          <el-input type="textarea" :rows="5" v-model="form.suggestReply" placeholder="请输入回复内容"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="submit-btn-ok">
      <el-button type="primary" @click="handleOk">提交</el-button>
    </div>
  </div>
</template>

<script>
import { getEvaluateDetail, evaluateReply } from '@/api/evaluate';
export default {
  data() {
    return {
      id: undefined,
      isModifyReply: undefined,
      type: undefined,
      boxLoading: false,
      detailsInfo: {},
      form: {
        evaluationId: undefined,
        suggestReply: undefined
      },
      rules: {
        evaluationReply: [{ required: true, message: '请输入回复内容', trigger: 'blur' }]
      }
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.isModifyReply = this.$route.query.isModifyReply;
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.boxLoading = true;
        this.detailsInfo = (await getEvaluateDetail({ data: this.id })).data || {};
        this.detailsInfo.suggestPictureUrl = JSON.parse(this.detailsInfo.suggestPictureUrl);

        if (!this.isModifyReply && !!this.detailsInfo.suggestReply) {
          this.form.suggestReply = this.detailsInfo.suggestReply;
        }

        this.boxLoading = false;
      } catch (error) {
        this.boxLoading = false;
      }
    },
    handleGo() {
      window.history.pushState(null, null, `/index/supplyorder/orderDetail?serviceId=${this.detailsInfo.serviceId}`);
    },
    handleOk() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoadingStatus = true;
          let formData = JSON.parse(JSON.stringify(this.form));
          formData.evaluationId = this.id;
          evaluateReply({ data: formData })
            .then(res => {
              this.$notify.success({ title: '成功', message: '提交成功', duration: 2000 });
              this.$router.back();
              this.submitLoadingStatus = false;
            })
            .catch(err => {
              this.submitLoadingStatus = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.reply-supply-evaluation-list {
  background-color: #fff;
  font-size: 14px;
  .info-cotainer {
    padding: 24px 20px;
  }
  .hr {
    height: 16px;
    background-color: #f7f7f7;
  }
  .detail-title {
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: flex;
    align-items: center;
    color: #000000;
    padding: 11px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 20px;
    .title-mark {
      width: 3px;
      height: 20px;
      margin: 0 20px 0 -20px;
      background-color: #363f47;
      border-radius: 0px 3px 3px 0px;
    }
    .title-text {
      margin-right: 20px;
    }
  }
  .score {
    background: #f7f7f7;
    margin: 20px;
    .score-content {
      border-bottom: 1px solid #ededed;
      padding: 20px;
      .text {
        padding: 20px 0 20px 0;
      }
    }
    .score-img {
      margin-right: 16px;
    }
  }
  .score-text-area {
    padding: 20px;
  }
  .certificate-img {
    width: 100px;
    height: 100px;
  }
  .certificate-img:hover {
    cursor: pointer;
  }
  .submit-btn-ok {
    text-align: right;
    margin-right: 20px;
  }
}
</style>
